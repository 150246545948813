import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExcelExportService } from 'src/app/shared/shared-service/excel-export.service';
import { VimbikaService } from 'src/app/shared/shared-service/vimbika.service';
import { NotifyUtil } from 'src/app/util/notifyutil';

@Component({
  selector: 'app-bank-reconcilliation',
  templateUrl: './bank-reconcilliation.component.html',
  styleUrls: ['./bank-reconcilliation.component.css']
})
export class BankReconcilliationComponent implements OnInit, OnDestroy {

  list: any[]
  displayItems = []
  sortName = null
  sortValue = null
  listOfSearchName = []
  searchAddress: string
  loading = true
  isModalVisible = false
  deleteId: string
  roles: Array<string>
  branchList$: Observable<any[]>
  userList$: Observable<any[]>
  displayBankList$: Observable<any[]>
  canDelete = true
  id: string
  form: FormGroup
  searchForm: FormGroup
  showForm = false
  currencyList$: Observable<any[]>
  bankList$: Observable<any[]>
  type: boolean
  isConfirmLoading = false
  private destroy$: Subject<void> = new Subject()
  util = new NotifyUtil(this.notification)

  constructor(
    private service: VimbikaService,
    private notification: SnotifyService,
    private activatedRouter: ActivatedRoute,
    private excelService: ExcelExportService,
    private fb: FormBuilder,
  ) {
    this.id = this.activatedRouter.snapshot.params.id
  }
  ngOnInit() {
    this.createSearchForm()
    this.createForm()
    this.getBranches()
    this.getUsers()
    this.getCurrencies()
    this.getDisplayBanks()
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  createForm() {
    this.form = this.fb.group({
      type: [null],
      currency: [null],
      bank: [null],
      branch: [null],
      reference: [null],
      credit: [0],
      debit: [0],
    })
  }

  createSearchForm() {
    this.searchForm = this.fb.group({
      startDate: [null],
      endDate: [null],
      bank: [null],
      branch: [null],
      user: [null],
    })
  }

  getItems() {
    this.service
      .getItems(this.searchForm.value, '/statement/get-all')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        result => {
          this.list = result
          this.displayItems = [...this.list]
          this.loading = false
        },
        error => {
          this.loading = false
          this.notification.error(error.message, 'Error Encountered', this.util.getNotifyConfig())
        },
      )
  }

  getDisplayBanks() {
    this.displayBankList$ = this.service.getAll('/bank/get-all')
  }

  getBanks(value) {
    this.bankList$ = this.service.getAll('/bank/by-currency/' + value.id)
  }

  getCurrencies() {
    this.currencyList$ = this.service.getAll('/currency/get-all')
  }

  getBranches() {
    this.branchList$ = this.service.getAll('/branch/get-all')
  }

  getUsers() {
    this.userList$ = this.service.getAll('/user/get-all')
  }

  configure(value) {
    if (value === 'DEBIT') {
      this.type = true
      this.form.get('credit').setValue(null)
    } else {
      this.type = false
      this.form.get('debit').setValue(null)
    }
  }

  exportAsExcelFile() {
    const filtered = JSON.parse(JSON.stringify(this.displayItems))
    filtered.forEach(obj => {
      obj['Date'] = obj.dateCreated
      obj['Time'] = obj.time
      obj['User'] = obj.createdByName
      obj['Bank'] = obj.bank.bankName
      obj['ReferenceNumber'] = obj.reference
      obj['Debit'] = obj.debit != null ? obj.currency.symbol + obj.debit : ''
      obj['Credit'] = obj.credit != null ? obj.currency.symbol + obj.credit : ''
      obj['Balance'] = obj.currency.symbol + obj.bank.balance
      delete obj.currency
      delete obj.debit
      delete obj.reference
      delete obj.credit
      delete obj.dateCreated
      delete obj.dateModified
      delete obj.createdByName
      delete obj.modifiedByName
      delete obj.version
      delete obj.active
      delete obj.deleted
      delete obj.id
      delete obj.uuid
      delete obj.time
      delete obj.bank
      delete obj.balance
      delete obj.originalBalance
      delete obj.transactionDate
      delete obj.branch
      delete obj.company
      delete obj.paymentDescription
    })
    this.excelService.exportAsExcelFile(filtered, 'bank_reconcilliation')
  }

  submitForm(): void {
    this.isConfirmLoading = true
    if (this.form.valid) {
      this.service
        .save(this.form.value, '/statement/save')
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          result => {
            this.isConfirmLoading = false
            this.notification.success(result.message, 'Success', this.util.getNotifyConfig())
            this.form.reset()
            this.showForm = false
            localStorage.setItem('index', '1')
            window.location.reload()
          },
          error => {
            this.isConfirmLoading = false
            this.notification.error(error.message, 'Error Encountered', this.util.getNotifyConfig())
          },
        )
    }
  }

  currencyChange(value) {
    this.getBanks(value)
  }

  test(value) {
    console.log(value)
  }

}
