import { Component, OnInit, ViewChild } from '@angular/core';
import { Client } from 'src/app/shared/business/model/client/client.model';
import { ClientService } from 'src/app/shared/business/service/client.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/util/app-date-adapter';
import { DateUtil } from 'src/app/util/date-util';
import { Global } from 'src/app/global';
import { ExcelService } from 'src/app/shared/business/service/excel.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { VimbikaService } from 'src/app/shared/shared-service/vimbika.service';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'app-client-report',
  templateUrl: './client-report.component.html',
  styleUrls: ['./client-report.component.css'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    },
    DatePipe
  ]
})
export class ClientReportComponent implements OnInit {

  items: any[];
  filteredData: any[];
  excelData: any[] = [];
  @ViewChild('table') table: any;
  form: FormGroup;
  activeDiv = true;
  filtered: any[];
  maxDate = new Date();
  classBalance = 'primary';
  contractLink: string;
  totalMortgageValue: number;
  totalPropertyValue: number;
  white = 'assets/images/white.png';
  purple = 'assets/images/purple.png';
  constructor(
    private service: VimbikaService,
    private fb: FormBuilder,
    private excelService: ExcelService,
    private datePipe: DatePipe,
    public global: Global
  ) { }

  ngOnInit() {
    //this.fetchItems();
    this.createForm();

  }

  CalculateTotalMortgageValue() {
    let total = 0;
    this.items.forEach(item => {
      if (item.catalogue !== null) {
        total += Number(item.catalogue.mortgageValue);
      }
    });
    this.totalMortgageValue = total;
  }

  CalculateTotalPropertyValue() {
    let total = 0;
    this.items.forEach(item => {
      if (item.catalogue !== null) {
        total += Number(item.catalogue.totalCost);
      }
    });
    this.totalPropertyValue = total;
  }

  configure() {
    const val = this.form.get('filterOptions').value;
    if (val !== 'Client' || val !== 'Deposits') {
      this.form.get('startDate').disable();
      this.form.get('endDate').disable();
    }
    if (val === 'Client' || val === 'Deposits') {
      this.form.get('startDate').enable();
      this.form.get('endDate').enable();
    }
  }

  createForm() {
    this.form = this.fb.group({
      search: new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      filterOptions: new FormControl('')
    });
  }

  searchDates(value): any[] {
    const startDate = new Date(value.startDate);
    const endDate = new Date(value.endDate);
    const temp = this.filteredData.filter(function (d) {
      const dateToSearch = new Date(d.dateCreated);
      return (dateToSearch >= startDate && dateToSearch <= endDate);
    });
    return temp;
  }

  onChange(value) {
    if (value.startDate && value.endDate) {
      this.clearSearch();
      this.fetchTransactionsByDateRange(value);
    }
  }

  fetchTransactionsByDateRange(value) {
    const startDate = new Date(value.startDate);
    startDate.setMinutes(startDate.getTimezoneOffset() * -1);
    value.startDate = this.datePipe.transform(startDate, 'yyyy-MM-dd');

    const endDate = new Date(value.endDate);
    endDate.setMinutes(endDate.getTimezoneOffset() * -1);
    value.endDate = this.datePipe.transform(endDate, 'yyyy-MM-dd');
    this.service.getItems(value, '/customer/get-clients-by-date-range').subscribe(
      data => {
        this.filteredData = [...data];
        this.items = data;
      },
      error => {
        console.log(error.error);
      }
    );
  }

  exportAsXLSX() {
    const filtered = []
    this.items.forEach(x => filtered.push(this.createExcelObject(x)))
    this.excelService.exportAsExcelFile(filtered, 'new_client_report')
  }

  createExcelObject(row): any {
    return {
      emh_no: row?.customerId,
      name: row?.name,
      phone: row?.contact,
      address: row?.address,
      joining_date: row?.joiningDate,
      country: row?.country,
      area: row?.location,
      size: row?.standSize + ' ' + row?.option,
      term: row?.term,
      property_value: row?.propertyValue,
      total_deposits: row?.totalDeposits,
      percentage_paid: row.percentagePaid + '%'
    }
  }


  clearSearch() {
    this.form.controls['search'].setValue(null);
  }

  searchClientUUID(val): any[] {
    const temp = this.filteredData.filter(function (d) {
      return d.clientUUID !== undefined && d.clientUUID.toLowerCase().indexOf(val) !== -1 || !val;
    });
    return temp;
  }

  searchFullName(val): any[] {
    const temp = this.filteredData.filter(function (d) {
      return d.fullName.toLowerCase().indexOf(val) !== -1 || !val;
    });
    return temp;
  }

  searchCity(val): any[] {
    const temp = this.filteredData.filter(function (d) {
      return d.address.city.toLowerCase().indexOf(val) !== -1 || !val;
    });
    return temp;
  }

  searchAllocationStatus(val): any[] {
    if (val.toLowerCase() === 'allocated') {
      val = 'assets/images/green';
      console.log('wataa')
    }
    if (val.toLowerCase() === 'pending') {
      val = 'assets/images/blue';
    }
    if (val.toLowerCase() === 'notdue') {
      val = 'assets/images/yellow';
    }
    const temp = this.filteredData.filter(function (d) {
      return d.status.toLowerCase().indexOf(val) !== -1 || !val;
    });
    return temp;
  }

  searchPaymentStatus(val): any[] {
    if (val.toLowerCase() === 'notfullypaid') {
      val = 99;
      const temp = this.filteredData.filter(function (d) {
        return d !== undefined && d.percentagePaid < 100;

      });
      return temp;
    }
    else if (val.toLowerCase() === 'fullypaid') {
      val = 100;
      const temp = this.filteredData.filter(function (d) {
        return d !== undefined && d.percentagePaid >= 100;

      });
      return temp;
    }
    else {
      const temp = this.filteredData.filter(function (d) {
        return d !== undefined;

      });
      return temp;
    }
  }

  searchCountry(val): any[] {
    const temp = this.filteredData.filter(function (d) {
      return d.address.country.name !== null && d.address.country.name.toLowerCase().indexOf(val) !== -1 || !val;
    });
    return temp;
  }

  searchPreferredArea(val): any[] {
    const temp = this.filteredData.filter(function (d) {
      return d.area !== null && d.area.name.toLowerCase().indexOf(val) !== -1;
    });
    return temp;
  }

  searchPopertyOptions(val): any[] {
    const temp = this.filteredData.filter(function (d) {
      return d.catalogue !== null && d.catalogue.propertyOptions.toString().toLowerCase().indexOf(val) !== -1 || !val;
    });
    return temp;
  }

  searchPopertyValue(val): any[] {
    const temp = this.filteredData.filter(function (d) {
      return d.catalogue !== null && d.catalogue.totalCost === Number(val);
    });
    return temp;
  }

  searchMortgageValue(val): any[] {
    const temp = this.filteredData.filter(function (d) {
      return d.catalogue !== null && d.catalogue.mortgageValue === Number(val);
    });
    return temp;
  }

  searchInstalment(val): any[] {
    const temp = this.filteredData.filter(function (d) {
      return d.catalogue !== null && d.catalogue.monthlyPayment === Number(val);
    });
    return temp;
  }

  searchPercentage(val): any[] {
    const temp = this.filteredData.filter(function (d) {
      return d.percentagePaid !== null && d.percentagePaid <= Number(val);
    });
    return temp;
  }

  searchTotalDeposits(val): any[] {
    const temp = this.filteredData.filter(function (d) {
      return d.totalDeposits !== null && d.totalDeposits <= Number(val);
    });
    return temp;
  }
  getContractPdf() {
    this.contractLink = '/api/business/client/excel/clients';
    const url = this.global.baseUrl + this.contractLink;
    window.open(url, '_blank');

  }



  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    this.items = this.searchClientUUID(val);
    if (this.items.length === 0) {
      this.items = this.searchAllocationStatus(val);
      if (this.items.length === 0) {
        this.items = this.searchFullName(val);
        if (this.items.length === 0) {
          this.items = this.searchPaymentStatus(val);
          if (this.items.length === 0) {
            this.items = this.searchPercentage(val);
          }
        }
      }
    }
    this.table.offset = 0;
    this.CalculateTotalPropertyValue();
    this.CalculateTotalMortgageValue();
  }
}
