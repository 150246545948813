import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { Observable, Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { CrudService } from 'src/app/shared/shared-service/crud.service';
import { VimbikaService } from 'src/app/shared/shared-service/vimbika.service';
import { DateUtil } from 'src/app/util/date-util';
import { NotifyUtil } from 'src/app/util/notifyutil';

@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.css'],
  providers: [DatePipe]
})
export class AssetComponent implements OnInit, OnDestroy {

  form: FormGroup
  duplicate = false
  isSaveLoading = false
  id: string
  assetCategories$: Observable<any[]>
  expenseCategories$: Observable<any[]>
  currencies$: Observable<any[]>
  compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue
  data: any
  banks: any[]
  displayBanks: any[]
  private destroy$: Subject<void> = new Subject()
  util = new NotifyUtil(this.notification)

  constructor(
    private fb: FormBuilder,
    private service: VimbikaService,
    private activatedRouter: ActivatedRoute,
    private notification: SnotifyService,
    private router: Router,
    private datePipe: DatePipe,
  ) {
    this.id = this.activatedRouter.snapshot.params.id
  }

  ngOnInit() {
    this.fetchAssetCategories()
    this.fetchExpenseCategories()
    this.fetchCurrencies()
    this.getBanks()
    this.createForm()
    this.updateOnChange()
  }
  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit() {
    if (this.id !== undefined && this.id !== null) {
      this.getItem()
    }
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  createForm() {
    this.form = this.fb.group({
      id: [null],
      version: [null],
      createdByName: [null],
      dateCreated: [null],
      uuid: [null],
      name: [null, [Validators.maxLength(45), Validators.minLength(2), Validators.required]],
      description: [null],
      purchaseValue: [null],
      resaleValue: [null],
      usefulLife: [null],
      depreciation: [null],
      currentValue: [null],
      assetCategory: [null],
      expenseCategory: [null],
      dateOfPurchase: [null],
      currentDepreciation: [null],
      totalDepreciation: [null],
      currency: [null],
      tagNumber: [null],
      bank: [null],
      baseCurrencyValue: [null],
    })
  }
  edit(data: any) {
    this.form.get('id').setValue(data.id)
    this.form.get('version').setValue(data.version)
    this.form.get('createdByName').setValue(data.createdByName)
    this.form.get('dateCreated').setValue(data.dateCreated)
    this.form.get('uuid').setValue(data.uuid)
    this.form.get('name').setValue(data.name)
    this.form.get('description').setValue(data.description)
    this.form.get('purchaseValue').setValue(data.purchaseValue)
    this.form.get('resaleValue').setValue(data.resaleValue)
    this.form.get('usefulLife').setValue(data.usefulLife)
    this.form.get('depreciation').setValue(data.depreciation)
    this.form.get('currentValue').setValue(data.currentValue)
    this.form.get('assetCategory').setValue(data.assetCategory)
    this.form.get('expenseCategory').setValue(data.expenseCategory)
    this.form.get('dateOfPurchase').setValue(data.dateOfPurchase)
    this.form.get('currentDepreciation').setValue(data.currentDepreciation)
    this.form.get('totalDepreciation').setValue(data.totalDepreciation)
    this.form.get('currency').setValue(data.currency)
    this.form.get('tagNumber').setValue(data.tagNumber)
    this.form.get('bank').setValue(data.bank)
    this.form.get('baseCurrencyValue').setValue(data.baseCurrencyValue)
  }
  submitForm() {
    this.isSaveLoading = true
    this.validateForm()
    const formattedDate = this.datePipe.transform(this.form.value.dateOfPurchase, 'yyyy-MM-dd')
    const data = this.form.value
    data.dateOfPurchase = formattedDate
    if (this.form.valid) {
      this.service
        .save(data, '/asset/save')
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          result => {
            if (!result.duplicate) {
              this.notification.success(result.message, 'Notification', this.util.getNotifyConfig())
              this.router.navigateByUrl('/asset-register/asset')
            } else {
              this.duplicate = true
              this.notification.warning('Asset already exist!','Duplicate Name', this.util.getNotifyConfig())
            }
            this.isSaveLoading = false
          },
          error => {
            this.isSaveLoading = false
            this.notification.error(error.message, 'Error Encountered', this.util.getNotifyConfig())
          },
        )
    }
  }
  validateForm() {
    // tslint:disable-next-line: forin
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty()
      this.form.controls[i].updateValueAndValidity()
    }
  }
  cancel() {
    this.router.navigateByUrl('/asset-register/asset')
  }

  getItem() {
    this.service
      .getItem('/asset/get-item/' + this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        result => {
          this.edit(result)
        },
        error => this.notification.error(error.message, 'Error Encountered', this.util.getNotifyConfig())
      )
  }

  fetchAssetCategories() {
    this.assetCategories$ = this.service.getAll('/asset-category/get-all')
  }

  fetchExpenseCategories() {
    this.expenseCategories$ = this.service.getAll('/expense/category/get-all')
  }

  fetchCurrencies() {
    this.currencies$ = this.service.getAll('/currency/get-all')
  }

  compareByValue(f1: any, f2: any) {
    return f1 && f2 && f1.id === f2.id
  }

  calculateDepreciation(data) {
    if (data.purchaseValue !== null && data.resaleValue !== null && data.usefulLife !== null) {
      let depreciation = Number(
        ((data.purchaseValue - data.resaleValue) / data.usefulLife).toFixed(2),
      )
      this.form.get('depreciation').setValue(depreciation)
      this.form.get('totalDepreciation').setValue(data.purchaseValue - data.resaleValue)
    }

    if (
      data.purchaseValue !== null &&
      data.resaleValue !== null &&
      data.usefulLife !== null &&
      data.dateOfPurchase != null
    ) {
      let depreciationToDate =
        DateUtil.getMonthsBetween(new Date(), new Date(data.dateOfPurchase)) *
        this.form.get('depreciation').value
      let currentValue = data.purchaseValue - depreciationToDate
      this.form.get('currentValue').setValue(currentValue)
      this.form.get('currentDepreciation').setValue(depreciationToDate)
    }
  }

  updateOnChange() {
    this.form.valueChanges.pipe(debounceTime(300)).subscribe(result => {
      this.calculateDepreciation(result)
    })
  }

  getBanks() {
    this.service
      .getAll('/bank/get-all')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        result => {
          this.banks = result
        },
        error => this.notification.error(error.message, 'Error Encountered', this.util.getNotifyConfig())
      )
  }

  loadBanks(value) {
    this.displayBanks = this.banks.filter(b => b.currency.id === value.id)
  }

}
