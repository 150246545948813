import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-setting-dashboard',
  templateUrl: './setting-dashboard.component.html',
  styleUrls: ['./setting-dashboard.component.css']
})
export class SettingDashboardComponent implements OnInit {
  public  = localStorage.getItem('COLOR');
  constructor() { }

  ngOnInit() {
  }

}
