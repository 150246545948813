import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AccessDeniedComponent } from '../layout/access-denied/access-denied.component';

const routes: Routes = [
    {
        path: '', component: LoginComponent
  },
  {
    path: 'forgot-password', component: ForgotPasswordComponent
  },

  {
    path: 'access-denied', component: AccessDeniedComponent
  },
  {
    path: 'reset-password/:token', component: ResetPasswordComponent
  }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LoginRoutingModule {}
