import { Component, OnInit } from '@angular/core';
import { trigger } from '@angular/animations';
import { transitionAnimation } from '../animations/shared-animate';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../core/auth.service';
import { TokenStorage } from '../core/token.storage';
import { CompanyProfileService } from '../shared/config/service/admin/company-profile.service';
import { Global } from '../global';
import { StorageKey } from '../util/key';
import { NgxPermissionsService } from 'ngx-permissions';
import { UserRoleService } from '../shared/config/service/admin/user-role.service';
import { CrudService } from '../shared/shared-service/crud.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [
    trigger('animate', transitionAnimation)
]
})
export class LoginComponent implements OnInit {



  password: string;
  userName: string;
  hide = true;
  theme: string;

  constructor(private router: Router, public global: Global, private roleService: UserRoleService,
    private authService: AuthService, private token: TokenStorage, private service: CrudService
    ) {
  }


  ngOnInit() {
    localStorage.removeItem('AuthToken');
    sessionStorage.removeItem(StorageKey.GRANTED_AUTHORITIES);
   // this.permissionsService.flushPermissions();
    this.theme = localStorage.getItem('COLOR');
  }

  login(): void {
    this.authService.attemptAuth(this.userName, this.password).subscribe(
      data => {
        this.token.saveToken(data.token);
        localStorage.setItem(StorageKey.USER, JSON.stringify(data));
        sessionStorage.setItem(StorageKey.EMAIL, this.userName)
        sessionStorage.setItem(StorageKey.IDENTIFIER, this.password)
      },
       error => {
      },
      () => {
        //this.getVimbikaUser();
        this.loginVimbika()
       }
    );
  }
  forgotPassword() {
    this.router.navigate(['forgot-password']);
  }

  loginVimbika(): void {
    this.authService.attemptVimbikaAuth(this.userName, this.password).subscribe(
      data => {
        console.log(data)
        this.token.saveVimbikaToken(data.token)
        sessionStorage.setItem(StorageKey.VIMBIKA, data.company.id)
      },
      error => {

      },
      () => this.getRoles()
    )
  }

  getVimbikaUser() {
    this.service.save({userName: this.userName, password: this.password}, '/login').subscribe(
      data => {
        if(data.company !== null) {
          sessionStorage.setItem(StorageKey.VIMBIKA, data.company.id)
        }
    },
      error => {
        this.getRoles();
      },
      () => {
        this.getRoles();
      }
    )
  }

  getUser() {
    return this.authService.getUser().subscribe(
      data => {
        localStorage.setItem(StorageKey.USER, JSON.stringify(data));
      }
    );
  }

  getRoles() {
    this.roleService.getGrantedAuthorities().subscribe(
      result => {
        const allRoles = JSON.stringify(result);
        sessionStorage.setItem(StorageKey.GRANTED_AUTHORITIES, allRoles);
        if (result.length === 1) {

        }
      },
      error => {
        console.log(error);
      },
      () => {
        let roles = JSON.parse(sessionStorage.getItem(StorageKey.GRANTED_AUTHORITIES));
        if(roles.includes('ROLE_SUPER_ADMIN')) {
          this.router.navigate(['client']);
        }
        else if(roles.includes('ROLE_ADMIN')) {
          this.router.navigate(['client']);
        }
        else if (roles.includes('ROLE_DATA_CLERK')) {
          this.router.navigate(['client']);
        }
        else if (roles.includes('ROLE_RECEPTION')) {
          this.router.navigate(['client']);
        }
        else if (roles.includes('ROLE_FINANCE')) {
          this.router.navigate(['client/que']);
        }
        else if (roles.includes('ROLE_SALES')) {
          this.router.navigate(['pos']);
        }
        else {
          this.router.navigate(['access-denied']);
        }
      }
    );
  }

}
