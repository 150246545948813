import { DatePipe } from "@angular/common";

export class DateUtil {
  static dateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"


  static getMonthNameFromDate(date: Date): string {
    const locale = 'en-us';
    const dayOfMonth = date.getDate();
    const dayOfWeek = date.toLocaleString(locale, {weekday: 'long'}).substring(0, 3);
    const month = date.toLocaleString(locale, {month: 'long'}).substring(0, 3);
    return dayOfMonth + ' ' + month + ', ' + dayOfWeek;
  }

  static getFriendlyName(date: Date): string {
    const locale = 'en-us';
    const dayOfMonth = date.getDate();
    const month = date.toLocaleString(locale, {month: 'long'}).substring(0, 3);
    const year = date.toLocaleString(locale, {year: '2-digit'});
    return dayOfMonth + ' ' + month + ' ' + year ;
  }

  static getNextDays(date: Date): string[] {
    const result = [];
    result.push(this.getMonthNameFromDate(date));
    for (let i = 1; i <= 6; i++) {
      const toUse = new Date();
      toUse.setDate(date.getDate() + i);
      result.push(this.getMonthNameFromDate(toUse));
    }
    return result;
  }

  static getDateDiff(date: Date, factor: number): Date {
    const toUse = new Date();
    toUse.setDate(date.getDate() + factor);
    return toUse;
  }

  static getYearsBetween(now: Date, then: Date): number {
    return Math.floor((now.getTime() - then.getTime()) /12/30/24/3600/1000);
  }
  static formatDate(date: string, datepipe: DatePipe): any {
    const d = datepipe.transform(date, this.dateFormat)
    return d
  }

  static getMonthsBetween(now: Date, then: Date): number {
    var d = then
    if (d.getDate() > 1) {
      d.setMonth(d.getMonth() + 1, 1)
    }
    let diff = (now.getFullYear() - d.getFullYear()) * 12 + (now.getMonth() - d.getMonth())
    if (now.getDate() < d.getDate()) {
      diff--
    }
    return diff
  }
}
