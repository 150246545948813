import { Global } from './../../../global';
import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CompanyProfileService } from 'src/app/shared/config/service/admin/company-profile.service';
import { UserRoleService } from 'src/app/shared/config/service/admin/user-role.service';
import { StorageKey } from 'src/app/util/key';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { Router } from '@angular/router';
import { VimbikaService } from 'src/app/shared/shared-service/vimbika.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));
  showSettings = false
  showHr = false
  showInventory = false
  showSales = false
  showFinance = false
  showVimbikaMenu = false
  showReports = false
  allowFinance = true


  constructor(
    private breakpointObserver: BreakpointObserver,
    private companyProfilService: CompanyProfileService,
    public dialog: MatDialog,
    public global: Global,
    private router: Router,
    private service: UserRoleService,
    private ngxRolesService: NgxRolesService,
    private permissionsService: NgxPermissionsService,
    private vimbikaService: VimbikaService
  ) {
  }


  ngOnInit(): void {
    this.getRoles();
    this.showVimbikaMenu = sessionStorage.getItem(StorageKey.VIMBIKA) !== null && sessionStorage.getItem(StorageKey.VIMBIKA) !== 'null' ? true : false
    if(this.showVimbikaMenu) {
      this.getCurrency()
    }
  }

  getRoles() {
    this.service.getGrantedAuthorities().subscribe(
      result => {
        const allRoles = JSON.stringify(result);
        sessionStorage.setItem(StorageKey.GRANTED_AUTHORITIES, allRoles);
        if (result.length === 1) {

        }
      },
      error => {
        console.log(error);
      },
      () => {
        let roles = JSON.parse(sessionStorage.getItem(StorageKey.GRANTED_AUTHORITIES));
        this.permissionsService.loadPermissions(roles);
        this.ngxRolesService.addRoles(roles);
      }
    );
  }
  ngOnDestroy(): void {
    this.permissionsService.flushPermissions();
  }

  toggleSettingsMenu() {
    this.showSettings = !this.showSettings
    this.showHr = false
    this.showInventory = false
    this.showSales = false
    this.showFinance = false
    this.showReports = false
  }

  toggleHrMenu() {
    this.showHr = !this.showHr
    this.showInventory = false
    this.showSettings = false
    this.showSales = false
    this.showFinance = false
    this.showReports = false
  }

  toggleInventoryMenu() {
    this.showInventory = !this.showInventory
    this.showHr = false
    this.showSettings = false
    this.showSales = false
    this.showFinance = false
    this.showReports = false
  }

  toggleSalesMenu() {
    this.showSales = !this.showSales
    this.showInventory = false
    this.showHr = false
    this.showSettings = false
    this.showFinance = false
    this.showReports = false
  }

  toggleFinanceMenu() {
    this.showFinance = !this.showFinance
    this.showInventory = false
    this.showHr = false
    this.showSettings = false
    this.showSales = false
    this.showReports = false
  }

  toggleReportsMenu() {
    this.showReports = !this.showReports
    this.showInventory = false
    this.showHr = false
    this.showSettings = false
    this.showSales = false
    this.showFinance = false
  }

  getCurrency() {
    this.vimbikaService.getItem('/currency/get-base-currency').subscribe(
      result => {
        if(result === null) {
          this.allowFinance = false
        }
      },
      error => {
        console.log(error)
      },
    )
  }

  logout() {
    sessionStorage.removeItem(StorageKey.COMPANY_DETAIL);
    localStorage.removeItem(StorageKey.USER);
    sessionStorage.removeItem(StorageKey.GRANTED_AUTHORITIES);
    sessionStorage.removeItem(StorageKey.EMAIL)
    sessionStorage.removeItem(StorageKey.IDENTIFIER)
    sessionStorage.removeItem(StorageKey.VIMBIKA)
    localStorage.removeItem('VISIT_ID')
    localStorage.removeItem('COMPANY_ID')
    localStorage.removeItem('VIEW_FORM')
    localStorage.removeItem('clientId')
    localStorage.removeItem('url')
    this.router.navigate(['/login']);
  }
}
