import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SseService {

  observeMessages(sseUrl: string): Observable<any> {
    return new Observable<any>(obs => {
      const es = new EventSource(sseUrl);
      es.addEventListener('message', (evt) => {
        obs.next(JSON.parse((<any>evt).data));
      });
      es.addEventListener('error', (evt) => {
        if(es.readyState == EventSource.CLOSED) {

        }else {
          es.close()
        }
      });
    });
  }
}
