import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportSettingsComponent implements OnInit {

  selectedIndex = 0;

  constructor() { }

  ngOnInit() {
  }

  selectedIndexChange(val: number ) {
    this.selectedIndex = val;
  }

}
