import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Global } from 'src/app/global';
import { StorageKey } from 'src/app/util/key';

@Injectable({
  providedIn: 'root'
})
export class VimbikaService {

  private baseUrl = this.global.baseUrl + '/api'
  private vimbikaUrl = this.global.vimbikaUrl + '/api'
  private changePasswordUrl = this.baseUrl + '/user/change-password'
  private headers = new HttpHeaders()
  .set('vimbika', sessionStorage.getItem(StorageKey.VIMBIKA))
  .set('userName', sessionStorage.getItem(StorageKey.EMAIL))
  .set('password', sessionStorage.getItem(StorageKey.IDENTIFIER))
  constructor(private http: HttpClient, private global: Global) {}

  save(t: any, url: string): Observable<any> {
    return this.http.post<any>(this.vimbikaUrl + url, t)
  }
  update(t: any, url: string): Observable<any> {
    return this.http.put(this.vimbikaUrl + url, t)
  }
  getItem(url: string): Observable<any> {
    return this.http.get<any>(this.vimbikaUrl + url)
  }

  delete(url: string): Observable<any> {
    return this.http.delete<any>(this.vimbikaUrl + url)
  }

  getAll(url: string): Observable<any[]> {
    return this.http.get<any[]>(this.vimbikaUrl + url)
  }

  getItems(t: any, url: string): Observable<any[]> {
    return this.http.post<any[]>(this.vimbikaUrl + url, t)
  }

  /*save(t: any, url: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + url, t, {'headers': this.headers})
  }
  update(t: any, url: string): Observable<any> {
    return this.http.put(this.baseUrl + url, t, {'headers': this.headers})
  }
  getItem(url: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + url, {'headers': this.headers})
  }

  delete(url: string): Observable<any> {
    return this.http.delete<any>(this.baseUrl + url, {'headers': this.headers})
  }

  getAll(url: string): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + url, {'headers': this.headers})
  }

  getAllCrossOrigin(url: string): Observable<any[]> {
    return this.http.get<any[]>(this.vimbikaUrl + url)
  }

  getItems(t: any, url: string): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + url, t, {'headers': this.headers})
  }*/

  public uploadFile(image: File, url: string, id: string): Observable<any> {
    const formData = new FormData()
    formData.append('image', image)
    formData.append('id', id)
    return this.http.post<any>(this.baseUrl + url, formData)
  }

  public saveImage(image: Blob, url: string, id: string): Observable<any> {
    const formData = new FormData()
    formData.append('image', image)
    formData.append('id', id)
    return this.http.post<any>(this.vimbikaUrl + url, formData)
  }

  public updateImage(image: Blob, url: string, id: string, name: string): Observable<any> {
    const formData = new FormData()
    formData.append('image', image)
    formData.append('id', id)
    formData.append('url', name)
    return this.http.post<any>(this.vimbikaUrl + url, formData)
  }

  public changePassword(id: string, password: string): Observable<any> {
    const formData = new FormData();
    formData.append('id', id.toString());
    formData.append('pass', password);
    return this.http.post<any>(this.changePasswordUrl, formData, {'headers': this.headers});
  }
}
