import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from 'src/app/global';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  private baseUrl = this.global.baseUrl + '/api'
  constructor(private http: HttpClient, private global: Global) {}

  save(t: any, url: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + url, t)
  }
  update(t: any, url: string): Observable<any> {
    return this.http.put(this.baseUrl + url, t)
  }
  getItem(url: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + url)
  }
  getAll(url: string): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + url)
  }

  delete(url: string): Observable<any> {
    return this.http.delete<any>(this.baseUrl + url)
  }

  getPdf(url: string, t: any): Observable<any> {
    let headers = new HttpHeaders;
    headers = headers.set('Accept', 'application/pdf');
    const httpOptions = {
      'responseType'  : 'arraybuffer' as 'json'
    };
    return this.http.get<any>(this.baseUrl + url, httpOptions);
  }
}
