import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AccessDeniedSharedModule } from '../core/access-denied.shared.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatInputModule} from '@angular/material/input';
import { MatCardModule} from '@angular/material/card';
import { MatToolbarModule} from '@angular/material/toolbar';
import { MatDividerModule} from '@angular/material/divider';

@NgModule({
    imports: [
        LoginRoutingModule,
        CommonModule,
    MatInputModule,
      AccessDeniedSharedModule,
        MatCheckboxModule,
        MatButtonModule,
        FlexLayoutModule,
        MatCardModule,
      MatIconModule,
      MatToolbarModule,
        MatDividerModule,
    FormsModule, ReactiveFormsModule, MatSnackBarModule
    ],
    declarations: [LoginComponent, ForgotPasswordComponent, ResetPasswordComponent]
})
export class LoginModule {}
