import { Global } from './../../../../global';
import { CompanyProfile } from '../../model/admin/company-profile';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyProfileService {

  private saveUrl = this.global.baseUrl + '/api/admin/company-profile/save';
  private saveLogoUrl =  this.global.baseUrl + '/api/admin/company-profile/save-logo';
  private getAllUrl =  this.global.baseUrl + '/api/admin/company-profile/get-all';
  private getThemeUrl = this.global.baseUrl + '/api/admin/company-profile/get-theme';
  private getItemUrl = this.global.baseUrl + '/api/admin/company-profile/get-item?id=';
  constructor(private httpClient: HttpClient, private global: Global) { }

  public save(item: CompanyProfile): Observable<any> {
    return this.httpClient.post<any>(this.saveUrl, item);
  }

  public getAll(): Observable<CompanyProfile[]> {
    return this.httpClient.get<CompanyProfile[]>(this.getAllUrl);
  }
  public getTheme(): Observable<any> {
    return this.httpClient.get<any>(this.getThemeUrl);
  }
  public saveLogo(file: Blob, id: number): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', id.toString());
    return this.httpClient.post<any>(this.saveLogoUrl, formData);
  }

  pushFileToStorage(
    file: File, name: string, templateColor: string, address: string, email: string,
     phoneNumber: string, officePhone: string): Observable<HttpEvent<{}>> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', name);
    formData.append('address', address);
    formData.append('email', email);
    formData.append('phoneNumber', phoneNumber);
    formData.append('officePhone', officePhone);
    console.log(officePhone);
    formData.append('templateColor', templateColor);
    const req = new HttpRequest('POST', this.saveUrl, formData, {
      reportProgress: true,
      responseType: 'text'
    });

    return this.httpClient.request(req);
  }
  public getItem(id: number): Observable<CompanyProfile> {
    return this.httpClient.get<CompanyProfile>(this.getItemUrl + id);
  }
}
