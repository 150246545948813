import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnotifyService, SnotifyToast } from 'ng-snotify';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VimbikaService } from 'src/app/shared/shared-service/vimbika.service';
import { NotifyUtil } from 'src/app/util/notifyutil';

@Component({
  selector: 'app-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.css']
})
export class AssetListComponent implements OnInit, OnDestroy {

  list: any[]
  displayItems = []
  sortName = null
  sortValue = null
  listOfSearchName = []
  searchAddress: string
  loading = true
  isModalVisible = false
  deleteId: string
  index = 0
  normal = true
  private destroy$: Subject<void> = new Subject()
  util = new NotifyUtil(this.notification)
  constructor(
    private service: VimbikaService,
    private router: Router,
    private notification: SnotifyService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    @Optional() private dialog: MatDialogRef<AssetListComponent>
  ) {}
  ngOnInit() {
    if (localStorage.getItem('index') !== null) {
      this.index = Number(localStorage.getItem('index'))
      localStorage.removeItem('index')
    }
    if (this.dialog !== null) {
      this.displayItems = this.data
      this.normal = false
      this.loading = false
    } else {
      this.getItems()
    }
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key
    this.sortValue = sort.value
    this.search()
  }

  filter(listOfSearchName: string[], searchAddress: string): void {
    this.listOfSearchName = listOfSearchName
    this.searchAddress = searchAddress
    this.search()
  }

  search(): void {
    // /** filter data **/
    const filterFunc = item =>
      (this.searchAddress ? item.address.indexOf(this.searchAddress) !== -1 : true) &&
      (this.listOfSearchName.length
        ? this.listOfSearchName.some(name => item.name.indexOf(name) !== -1)
        : true)
    const data = this.list.filter(item => filterFunc(item))
    /** sort data **/
    if (this.sortName && this.sortValue) {
      this.displayItems = data.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.sortName] > b[this.sortName]
            ? 1
            : -1
          : b[this.sortName] > a[this.sortName]
          ? 1
          : -1,
      )
    } else {
      this.displayItems = data
    }
  }
  getItems() {
    this.service
      .getAll('/asset/get-all')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        result => {
          this.list = result
          this.displayItems = [...this.list]
          this.loading = false
        },
        error => {
          this.loading = false
          this.notification.error(error.message, 'Error Encountered', this.util.getNotifyConfig())
        },
      )
  }

  delete(id) {
    const noAction = (toast: SnotifyToast) => {
      this.notification.remove(toast.id);
    };
    const yesAction = (toast: SnotifyToast) => {
      this.deleteItem(id);
      this.notification.remove(toast.id);
    };
    this.notification.confirm('Are you sure?', 'Delete Item', {
      ...this.util.getNotifyConfigPrompt(),
      buttons: [
        {text: 'Yes', action: yesAction, bold: true },
        {text: 'No', action: noAction },
      ],
    });
  }

  deleteItem(id) {
    this.service
      .delete('/asset/delete/' + id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        result => {
          this.list = this.list.filter(item => item.id !== id)
          this.list = [...this.list]
          this.displayItems = this.displayItems.filter(item => item.id !== id)
          this.displayItems = [...this.displayItems]
          this.notification.info(result.message, 'Delete Notification!', this.util.getNotifyConfig())
          this.router.navigateByUrl('/asset-register/asset')
        },
        error => {
          this.notification.error(error.message, 'Error Encountered', this.util.getNotifyConfig())
        },
      )
  }

  edit(id) {
    this.router.navigateByUrl('/asset-register/asset/edit/' + id)
  }

  cancel() {
    this.dialog.close()
  }

}
