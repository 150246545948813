import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { VimbikaService } from 'src/app/shared/shared-service/vimbika.service';
import { Subject } from 'rxjs';
import { SnotifyService } from 'ng-snotify';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-financial-report',
  templateUrl: './financial-report.component.html',
  styleUrls: ['./financial-report.component.css']
})
export class FinancialReportComponent implements OnInit, OnDestroy {

  constructor( private fb: FormBuilder, private service: VimbikaService,
    private notification: SnotifyService
    ) { }
  filteredData: any;
  @ViewChild('table') table: any;
  items: any;
  form: FormGroup;
  user: any[];
  totalBalance: number;
  totalDeposits: number;
  classBalance = 'primary';
  white = 'assets/images/white.png';
  purple = 'assets/images/purple.png';
  private destroy$: Subject<void> = new Subject()
  ngOnInit() {
    this.createForm();
    this.fetchCashiers();
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }
  onDetailToggle(event) {
  }

  fetchCashiers() {
    this.service.getAll('/user/get-cashier').pipe(takeUntil(this.destroy$)).subscribe(
      data => this.user = data
    ),
    error => this.notification.error(error.message, 'Error')
  }

  fetchTransactionsByDateRange(value) {
    const startDate = new Date(value.startDate);
    startDate.setMinutes(startDate.getTimezoneOffset() * -1);
    value.startDate = startDate;

    const endDate = new Date(value.endDate);
    endDate.setMinutes(endDate.getTimezoneOffset() * -1);
    value.endDate = endDate;
    /*this.service.getClientsByDateRange(value).subscribe(
      data => {
        this.filteredData = [...data];
        this.items = data;
       // console.log(this.items);
        this.calculateTotalDeposited();
        this.calculateTotalBalance();
      } ,
      error => {
        console.log(error.error);
      }
    );*/
  }

  onChange(value) {
    if (value.startDate && value.endDate) {
      this.clearSearch();
    this.fetchTransactionsByDateRange(value);
    }
  }

  clearSearch(){
    this.form.controls['search'].setValue(null);
  }

  createForm() {
    this.form = this.fb.group({
      search: new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      cashier: new FormControl()
    });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
     this.items = this.searchClientUUID(val);
     if  (this.items.length === 0) {
       this.items = this.searchFullName(val);
     }
    this.table.offset = 0;
    this.calculateTotalDeposited();
    this.calculateTotalBalance();
    //this.calculateBalance();
  }

  searchClientUUID(val): any[] {
    const temp = this.filteredData.filter(function(d) {
      return d.clientUUID.toLowerCase().indexOf(val) !== -1 || !val;
    });
    return temp;
  }

  searchFullName(val): any[] {
    const temp = this.filteredData.filter(function(d) {
      return d.fullName.toLowerCase().indexOf(val) !== -1 || !val;
    });
    return temp;
  }

  calculateTotalDeposited(){
    let total = 0;
    this.items.forEach( item => {
      total += Number(item.totalDeposits);
    });
    this.totalDeposits = total;
  }
  calculateTotalBalance(){
    let total = 0;
    this.items.forEach( item => {
      total += Number(item.balance);
    })
    this.totalBalance = total;
  }


}
