import { Global } from './global';
import { ErrorHandler, InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './core/app-routing.module';
import { LoginModule } from './login/login.module';
import { LayoutMainModule } from './layout/layout.module';
import 'hammerjs';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from './core/inteceptor';
import { SnotifyService, ToastDefaults, SnotifyModule } from 'ng-snotify';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule} from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { AuthService } from './core/auth.service';
import { TokenStorage } from './core/token.storage';
import { Messages } from './util/messages';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { reducers, metaReducers } from './store/reducers';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ExcelService } from './shared/business/service/excel.service';
import { BrowserModule } from '@angular/platform-browser';
import { GlobalErrorHandler } from './global-error-handler';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';



@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    MatButtonModule,
    LoginModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LayoutMainModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatDividerModule,
    MatMenuModule,
    NoopAnimationsModule,
    SnotifyModule,
    NgxSpinnerModule,
    NgxPermissionsModule.forRoot(),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([]),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
        name: 'EMH'
      }),
  ],

  entryComponents: [],
  providers: [
    AuthService,
    TokenStorage,
    ExcelService,
    Messages,
    Global,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    SnotifyService,
    {
      provide: 'SnotifyToastConfig',
      useValue: ToastDefaults
    },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    { provide: InjectionToken, useValue: [] },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
