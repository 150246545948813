import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { ReportSettingsComponent } from './reports/report-settings/reports.component';
import { NgxPermissionsGuard } from 'ngx-permissions';


const routes: Routes = [
  {
    path: '', component: MainComponent,
    children: [
      {
        path: 'client',
        loadChildren: './client/client.module#ClientModule'
      },
      {
        path: 'admin',
        loadChildren: './admin/admin.module#AdminModule'
      },
      {
        path: 'inventory',
        loadChildren: './inventory/inventory.module#InventoryModule'
      },
      {
        path: 'finance',
        loadChildren: './finance/finance.module#FinanceModule'
      },
      {
        path: 'sales',
        loadChildren: './sales/sales.module#SalesModule'
      },
      {
        path: 'quotes',
        loadChildren: './quotes/quotes.module#QuotesModule'
      },
      {
        path: 'pos',
        loadChildren: './pos/pos.module#PosModule'
      },
      {
        path: 'customer',
        loadChildren: './customers/customers.module#CustomersModule'
      },
      {
        path: 'human-resources',
        loadChildren: './human-resource/human-resource.module#HumanResourceModule'
      },

      {
        path: 'expense',
        loadChildren: './expenses/expenses.module#ExpensesModule'
      },
      {
        path: 'supplier',
        loadChildren: './suppliers/suppliers.module#SuppliersModule'
      },
      {
        path: 'access-denied', component: AccessDeniedComponent
      },
      { path: 'report', component: ReportSettingsComponent },
      {
        path: 'asset-register',
        loadChildren: './asset-register/asset-register.module#AssetRegisterModule'
      },
      {
        path: 'financials',
        loadChildren: './financials/financials.module#FinancialsModule'
      }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
