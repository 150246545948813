import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SnotifyService, SnotifyToast } from 'ng-snotify';
import { Global } from 'src/app/global';
import { SseService } from 'src/app/shared/shared-service/sse.service';
import { StatementService } from 'src/app/shared/shared-service/statement.service';
import { StorageKey } from 'src/app/util/key';
import { NotifyUtil } from 'src/app/util/notifyutil';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  util = new NotifyUtil(this.notification)
  constructor(
    private service: SseService,
    private global: Global,
    private notification: SnotifyService,
    private statement: StatementService
  ) { }

  ngOnInit() {
    //this.getAlerts()
  }

  getAlerts() {
    this.service.observeMessages(this.global.vimbikaUrl + '/api/sse/register/' + sessionStorage.getItem(StorageKey.EMAIL)).subscribe(
      data => {
        console.log(data)
          this.notification.info(data.message.message, 'Info', this.util.getNotifyConfig())
          this.statement.result = true
      },
      error => console.log(error)
    )
  }

}
