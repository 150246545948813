import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VimbikaService } from 'src/app/shared/shared-service/vimbika.service';
import { NotifyUtil } from 'src/app/util/notifyutil';

@Component({
  selector: 'app-asset-category',
  templateUrl: './asset-category.component.html',
  styleUrls: ['./asset-category.component.css']
})
export class AssetCategoryComponent implements OnInit, OnDestroy {

  form: FormGroup
  duplicate = false
  isSaveLoading = false
  id: string
  private destroy$: Subject<void> = new Subject()
  util = new NotifyUtil(this.notification)
  constructor(
    private fb: FormBuilder,
    private service: VimbikaService,
    private activatedRouter: ActivatedRoute,
    private notification: SnotifyService,
    private router: Router,
  ) {
    this.id = this.activatedRouter.snapshot.params.id
  }

  ngOnInit() {
    this.createForm()
  }
  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit() {
    if (this.id !== undefined && this.id !== null) {
      this.getItem()
    }
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  createForm() {
    this.form = this.fb.group({
      id: [null],
      version: [null],
      createdByName: [null],
      dateCreated: [null],
      uuid: [null],
      name: [null, [Validators.maxLength(45), Validators.minLength(2), Validators.required]],
      description: [null],
    })
  }
  edit(data: any) {
    this.form.get('id').setValue(data.id)
    this.form.get('version').setValue(data.version)
    this.form.get('createdByName').setValue(data.createdByName)
    this.form.get('dateCreated').setValue(data.dateCreated)
    this.form.get('uuid').setValue(data.uuid)
    this.form.get('name').setValue(data.name)
    this.form.get('description').setValue(data.description)
  }
  submitForm() {
    this.isSaveLoading = true
    this.validateForm()

    if (this.form.valid) {
      this.service
        .save(this.form.value, '/asset-category/save')
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          result => {
            if (!result.duplicate) {
              this.notification.success(result.message, 'Notification', this.util.getNotifyConfig())
              this.router.navigateByUrl('/asset-register/category')
            } else {
              this.duplicate = true
              this.notification.warning('Category already exist!', 'Duplicate Name', this.util.getNotifyConfig())
            }
            this.isSaveLoading = false
          },
          error => {
            this.isSaveLoading = false
            this.notification.error(error.message, 'Error Encountered', this.util.getNotifyConfig())
          },
        )
    }
  }
  validateForm() {
    // tslint:disable-next-line: forin
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty()
      this.form.controls[i].updateValueAndValidity()
    }
  }
  cancel() {
    this.router.navigateByUrl('/asset-register/category')
  }

  getItem() {
    this.service
      .getItem('/asset-category/get-item/' + this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        result => {
          this.edit(result)
        },
        error => {
          this.notification.error(error.message, 'Error Encountered', this.util.getNotifyConfig())
        },
      )
  }

}
