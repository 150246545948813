import { Global } from './../../../global';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Bill} from '../model/billing/bill.model';



@Injectable({
  providedIn: 'root'
})
export class BillService {
  private getVisitTypesUrl = this.global.baseUrl + '/api/billing/visit-types';
  private getPaymentMethodsUrl = this.global.baseUrl + '/api/billing/payment-methods';
  private saveBillUrl = this.global.baseUrl + '/api/billing/save';
  private saveManualBillUrl = this.global.baseUrl + '/api/billing/save-manual';
  private getPayBillDetailsUrl = this.global.baseUrl + '/api/billing/get-pay-bill-details/';
  private getPayBillDetailsByPeriodUrl = this.global.baseUrl + '/api/billing/get-pay-bill-details-by-period/';
  private getBalanceUrl = this.global.baseUrl + '/api/billing/balance/';
  private visitStatusUrl = this.global.baseUrl + '/api/billing/visit-status/';
  private updateVisitBillUrl = this.global.baseUrl + '/api/billing/visit-bill';
  private getAccountStatementUrl = this.global.baseUrl + '/api/billing/account-statement/';
  private getManualPaymentsUrl = this.global.baseUrl + '/api/billing/manual-payments/';
  private getOldAccountStatementUrl = this.global.baseUrl + '/api/business/bill/account-statement/';
  private clientTotalPaymentsUrl = this.global.baseUrl + '/api/billing/total-payments/';
  private getInvoiceListUrl = this.global.baseUrl + '/api/billing/invoice-list/';
  private getLastBillUrl = this.global.baseUrl + '/api/billing/get-last-bill/';
  private getLastArrearUrl = this.global.baseUrl + '/api/billing/get-last-arrear/';
  private getTotalDepositsUrl = this.global.baseUrl + '/api/billing/total-deposits';
  private getTotalBalanceUrl = this.global.baseUrl + '/api/billing/total-balance';
 private getTotalTranscationsUrl = this.global.baseUrl + '/api/billing/get-all';
 private getTransactionsByDateRangeUrl = this.global.baseUrl + '/api/billing/get-deposits-by-date-range';
 private getReceiptDetailsUrl = this.global.baseUrl + '/api/billing/get-receipt-detail/';
 private deleteUrl = this.global.baseUrl + '/api/billing/delete?id=';

  constructor(private http: HttpClient, private global: Global) {}

  public getVisitTypes(): Observable<any[]> {
    return this.http.get<any[]>(this.getVisitTypesUrl);
   }
   public getPaymentMethods(): Observable<any[]> {
    return this.http.get<any[]>(this.getPaymentMethodsUrl);
   }
   public getPayBillDetails(patientId: number): Observable<any> {
    return this.http.get<any>(this.getPayBillDetailsUrl + patientId);
   }
   public getPayBillDetailsByPeriod(clientId: number, date: string): Observable<any> {
    return this.http.get<any>(this.getPayBillDetailsByPeriodUrl + clientId + '/' + date);
   }
   public getBalance(patientId: number): Observable<number> {
    return this.http.get<number>(this.getBalanceUrl + patientId);
   }
   public getReceiptDetails(patientId: number): Observable<any> {
    return this.http.get<any>(this.getReceiptDetailsUrl + patientId);
   }
   public getTranscations(): Observable<any> {
     return this.http.get<any>(this.getTotalTranscationsUrl);
   }
   public getTranscationsByDateRange(dateRange: any): Observable<any> {
    return this.http.post<any>(this.getTransactionsByDateRangeUrl, dateRange);
  }
   public getTotalBalance(): Observable<number> {
    return this.http.get<number>(this.getTotalBalanceUrl);
   }
   public getTotalDeposits(): Observable<number> {
    return this.http.get<number>(this.getTotalDepositsUrl);
   }
  public saveBill(bill: any): Observable<any> {
    return this.http.post<any>(this.saveBillUrl, bill);
  }

  public saveManualBill(bill: any): Observable<any> {
    return this.http.post<any>(this.saveManualBillUrl, bill);
  }

  public checkVisitStatus(patientId: number): Observable<any> {
    return this.http.get<any>(this.visitStatusUrl + patientId);
  }
  public updateVisitBill(visitId: string, billId: string): Observable<void> {
    let params = new HttpParams();
    params = params.append('visitId', visitId);
    params = params.append('billId', billId);
    return this.http.get<void>(this.updateVisitBillUrl, {params: params});
  }

  getClientAccountStatement(clientId: number): Observable<Bill[]> {
    return this.http.get<Bill[]>(this.getOldAccountStatementUrl + clientId);
  }

  getNewClientAccountStatement(clientId: number): Observable<Bill[]> {
    return this.http.get<Bill[]>(this.getAccountStatementUrl + clientId);
  }

  getManualPayments(clientId: number): Observable<Bill[]> {
    return this.http.get<Bill[]>(this.getManualPaymentsUrl + clientId);
  }
  getClientInvoicesList(clientId: number): Observable<Bill[]> {
    return this.http.get<Bill[]>(this.getInvoiceListUrl + clientId);
  }
  getClientTotalPayments(clientId: number): Observable<number> {
    return this.http.get<number>(this.clientTotalPaymentsUrl + clientId);
  }
  getLastBill(clientId: string): Observable<Bill> {
    return this.http.get<Bill>(this.getLastBillUrl + clientId);
  }
  getLastArrear(clientId: string): Observable<Bill> {
    return this.http.get<Bill>(this.getLastArrearUrl + clientId);
  }

  public delete(item: any): Observable<any> {
    return this.http.put<any>(this.deleteUrl, item);
  }
}
