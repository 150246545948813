import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavComponent } from './nav/side-nav/side-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { LayoutRoutingModule } from './layout-routing.module';
import { MainComponent } from './main/main.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {AccessDeniedSharedModule} from '../core/access-denied.shared.module';
import { ReportSettingsComponent } from './reports/report-settings/reports.component';
import { ClientReportComponent } from './reports/client-report/client-report.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FinancialReportComponent } from './reports/financial-report/financial-report.component';
import { FinancialStatementReportComponent } from './reports/financial-statement-report/financial-statement-report.component';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule} from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SettingDashboardComponent } from './admin/settings/setting-dashboard/setting-dashboard.component';
import { PositiveNumberPipe } from '../util/positive-number-pipe';
import { AssetCategoryListComponent } from './asset-register/asset-category-list/asset-category-list.component';
import { AssetCategoryComponent } from './asset-register/asset-category/asset-category.component';
import { AssetListComponent } from './asset-register/asset-list/asset-list.component';
import { AssetComponent } from './asset-register/asset/asset.component';
import { BankReconcilliationComponent } from './asset-register/bank-reconcilliation/bank-reconcilliation.component';
import { CurrentAssetsComponent } from './asset-register/current-assets/current-assets.component';
import { ManualPaymentsReportComponent } from './reports/manual-payments-report/manual-payments-report.component';


@NgModule({
  declarations: [
    MainComponent,
    SideNavComponent,
    ReportSettingsComponent,SettingDashboardComponent,
    ClientReportComponent,
    FinancialReportComponent,
    FinancialStatementReportComponent,
    ManualPaymentsReportComponent,
    AssetListComponent,
    CurrentAssetsComponent,
    BankReconcilliationComponent,
    AssetComponent,
    AssetCategoryListComponent,
    AssetCategoryComponent,
    PositiveNumberPipe,
  ],
  imports: [
    CommonModule,
    LayoutModule,
    LayoutRoutingModule, FlexLayoutModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    AccessDeniedSharedModule,
    MatIconModule,
    MatListModule,
    MatCardModule, MatDividerModule,
    NgxDatatableModule,
    MatSelectModule,
    MatGridListModule,
    MatMenuModule,
    MatDialogModule,
    MatTooltipModule,
    MatTabsModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatInputModule,
    NgxPermissionsModule.forChild()
  ],
  entryComponents: [],
  providers: []
})
export class LayoutMainModule { }

