import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VimbikaService } from 'src/app/shared/shared-service/vimbika.service';

@Component({
  selector: 'app-current-assets',
  templateUrl: './current-assets.component.html',
  styleUrls: ['./current-assets.component.css'],
})
export class CurrentAssetsComponent implements OnInit, OnDestroy {

  list: any[]
  displayItems = []
  sortName = null
  sortValue = null
  listOfSearchName = []
  searchAddress: string
  loading = true
  isModalVisible = false
  deleteId: string
  roles: any[]
  total: number
  currency: any
  private destroy$: Subject<void> = new Subject()
  searchForm: FormGroup
  constructor(
    private service: VimbikaService,
    private fb: FormBuilder,
    private notification: SnotifyService,
    @Inject(MAT_DIALOG_DATA) private data: any[],
    @Optional() public dialog: MatDialogRef<CurrentAssetsComponent>
  ) {
  }
  ngOnInit() {
    this.createSearchForm()
    if (this.dialog !== null) {
      this.displayItems = this.data
      this.total = this.displayItems.reduce(function(sum, x) {
        return x.bankName.includes('Accounts Payable')
          ? sum - x.baseCurrencyAmount
          : sum + x.baseCurrencyAmount
      }, 0)
      this.loading = false
    } else {
      this.getAssets()
    }
  }

  createSearchForm() {
    this.searchForm = this.fb.group({
      branch: [null],
    })
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key
    this.sortValue = sort.value
    this.search()
  }

  filter(listOfSearchName: string[], searchAddress: string): void {
    this.listOfSearchName = listOfSearchName
    this.searchAddress = searchAddress
    this.search()
  }

  search(): void {
    // /** filter data **/
    const filterFunc = item =>
      (this.searchAddress ? item.address.indexOf(this.searchAddress) !== -1 : true) &&
      (this.listOfSearchName.length
        ? this.listOfSearchName.some(name => item.name.indexOf(name) !== -1)
        : true)
    const data = this.list.filter(item => filterFunc(item))
    /** sort data **/
    if (this.sortName && this.sortValue) {
      this.displayItems = data.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.sortName] > b[this.sortName]
            ? 1
            : -1
          : b[this.sortName] > a[this.sortName]
          ? 1
          : -1,
      )
    } else {
      this.displayItems = data
    }
  }

  getAssets() {
    let data = this.searchForm.value
    this.service
      .getItems(data, '/statement/get-assets')
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        this.list = result
        this.displayItems = [...this.list]
        this.loading = false
      }),
      error => {
        console.log(error)
        this.loading = false
      }
  }

  cancel() {
    this.dialog.close()
  }

}
