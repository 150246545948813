import { Injectable } from '@angular/core';


const TOKEN_KEY = 'AuthToken';
const VIMBIKA_KEY = 'VimbikaToken'

@Injectable()
export class TokenStorage {

  constructor() { }

  /*signOut() {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.clear();
  }

  public saveToken(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY,  token);
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }*/

  signOut() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(VIMBIKA_KEY)
    localStorage.clear();
  }

  public saveToken(token: string) {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY,  token);
  }

  public getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  public saveVimbikaToken(token: string) {
    localStorage.removeItem(VIMBIKA_KEY);
    localStorage.setItem(VIMBIKA_KEY,  token);
  }

  public getVimbikaToken(): string {
    return localStorage.getItem(VIMBIKA_KEY);
  }
}
