import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

interface StatementResult {
  result: boolean
}

@Injectable({
  providedIn: 'root'
})
export class StatementService {

  private data: StatementResult = {
    result: false
  }

  private changesSubject$: Subject<boolean> = new Subject()
  changes$: Observable<boolean> = this.changesSubject$.asObservable()

  get result(): boolean{
    return this.data.result
  }

  set result(value: boolean) {
    this.data.result = value
    this.changesSubject$.next(value)
  }
  
  constructor() { }
}
