import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BillService } from 'src/app/shared/business/service/bill.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { CurrencyAmount } from 'src/app/shared/business/model/client/currency-amount.model';
import { Global } from 'src/app/global';
import { User } from 'src/app/shared/config/model/admin/user.model';
import { ExcelService } from 'src/app/shared/business/service/excel.service';
import { Subject } from 'rxjs';
import { VimbikaService } from 'src/app/shared/shared-service/vimbika.service';
import { takeUntil } from 'rxjs/operators';
import { SnotifyService } from 'ng-snotify';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-manual-payments-report',
  templateUrl: './manual-payments-report.component.html',
  styleUrls: ['./manual-payments-report.component.css'],
  providers: [DatePipe]
})
export class ManualPaymentsReportComponent implements OnInit, OnDestroy {
  items: any[];
  filteredData: any[];
  searchFilteredData: any[];
  things: CurrencyAmount[];
  form: FormGroup;
  users: any[];
  filtered: any[];
  totalAmountPaid: number;
  paymentMethods: any[];
  billPaymentMethod: any[];
  currencyAmount: number;
  contractLink: string;
  currencyAmountList: Array<CurrencyAmount> = [];
  paymentMethodsList: Array<CurrencyAmount> = [];
  currencies: any[]
  @ViewChild('dataTable') table: any;
  classBalance = 'primary';
  private destroy$: Subject<void> = new Subject()

  constructor(private fb: FormBuilder,private excelService: ExcelService, 
    private service: VimbikaService, private billService: BillService, 
    private notification: SnotifyService, private datePipe: DatePipe,
    public global: Global) { }

  ngOnInit() {
    this.getUsers()
    this.createForm();
    this.fetchCurrencies()
    this.fetchPaymentMethods();
  }

  getUsers() {
    this.service.getAll('/payments/received/get-cashier').pipe(takeUntil(this.destroy$)).subscribe(
      data => this.users = data
    ),
    error => this.notification.error(error.message, 'Error')
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  createForm() {
    this.form = this.fb.group({
      search: new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      cashier: new FormControl()
    });
  }
  clearCashier(){
    this.form.controls['cashier'].setValue(null);
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }
  onDetailToggle(event) {
  }

  exportAsXLSX():void {
    this.filtered = []
    this.items.forEach(x => this.filtered.push(this.createExcelObject(x)))
    this.filtered.forEach(obj => {
      obj['EMH No'] = obj.id
      obj['Amount Paid'] = obj.amount
      obj['Receipt No'] = obj.receiptNo
      obj['Description'] = obj.description
      obj['Cashier'] = obj.cashier
      obj['Date'] = obj.date
      delete obj.id;
      delete obj.amount;
      delete obj.receiptNo;
      delete obj.description;
      delete obj.cashier;
      delete obj.date;
    })
    this.excelService.exportAsExcelFile(this.filtered, 'Manual Payments');
 }

 createExcelObject(obj): any {
   return {
      id: obj.customerId,
      amount: obj.currency.symbol + obj.amount,
      receiptNo: obj.reference,
      description: obj.paymentNote,
      cashier: obj.createdByName,
      date: obj.paymentDate
   };
 }

  cashierClick(user: User) {

   this.items = this.searchCashier(user.userName.toLowerCase()); 
   this.searchFilteredData = [...this.items];
   
   this.table.offset = 0;
   this.calculateTotalAmountPaid();
   this.calculateTotalCurrencies();
   this.calculateTotalPaidByPaymentMethod();
     
  }
  getContractPdf()
  {
      this.contractLink = '/api/business/bill/excel/transactions';
      const url = this.global.baseUrl + this.contractLink;
      window.open(url, '_blank');
  }
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
     this.items = this.searchClientUUID(val);
     this.searchFilteredData = [...this.items];
     if  (this.items.length === 0) {
       this.items = this.searchReceiptNumber(val);
        this.searchFilteredData = [...this.items];
       if  (this.items.length === 0) {
         this.items = this.searchCashier(val);
          this.searchFilteredData = [...this.items];
      }
     }
    this.table.offset = 0;
    this.calculateTotalAmountPaid();
    this.calculateTotalCurrencies();
    this.calculateTotalPaidByPaymentMethod();
    //this.calculateBalance();
  }

  searchClientUUID(val): any[] {
    const temp = this.filteredData.filter(function(d) {
      return d.clientUUID !== null && d.clientUUID.toLowerCase().indexOf(val) !== -1 || !val;
    });
    return temp;
  }

  searchReceiptNumber(val): any[] {
    const temp = this.filteredData.filter(function(d) {
      return d.receiptNumber !== null && d.receiptNumber.toLowerCase().indexOf(val) !== -1 || !val;
    });
    return temp;
  }

  searchCashier(val): any[] {
    if(this.filteredData) {
      const temp = this.filteredData.filter(function(d) {
        return d.createdByName !== null && d.createdByName.toLowerCase().indexOf(val) !== -1 || !val;
      });
      console.log(temp)
      return temp;
    }
  }

  fetchTransactionsByDateRange(value) {
    const startDate = new Date(value.startDate);
    startDate.setMinutes(startDate.getTimezoneOffset() * -1);
    value.startDate = this.datePipe.transform(startDate, 'yyyy-MM-dd');

    const endDate = new Date(value.endDate);
    endDate.setMinutes(endDate.getTimezoneOffset() * -1);
    value.endDate = this.datePipe.transform(endDate, 'yyyy-MM-dd');
    this.service.getItems(value, '/payments/received/manual-payments-report').subscribe(
      data => {
        this.searchFilteredData = [...data];
        this.filteredData = [...data];
        this.items = data;
        this.calculateTotalAmountPaid();
        this.calculateTotalCurrencies();
        this.calculateTotalPaidByPaymentMethod();
      } ,
      error => {
        console.log(error.error);
      }
    );
  }

  searchDates(value): any[] {
    const startDate = new Date(value.startDate);
    const endDate = new Date(value.endDate);
    const temp = this.searchFilteredData.filter(function (d) {
      const dateToSearch = new Date(d.dateCreated);
      return (dateToSearch >= startDate && dateToSearch <= endDate);
    });
    return temp;
  }

  onChange(value) {
    if (value.startDate && value.endDate) {
      this.clearCashier();
      this.fetchTransactionsByDateRange(value);
    }

  }
  calculateTotalAmountPaid() {
    let total = 0;
    this.items.forEach(item => {
      total += Number(item.totalAmountPaid);
    });
    this.totalAmountPaid = total;
  }
  calculateTotalCurrencies() {
    const currencyAmountList = new Array<CurrencyAmount>();
    this.currencies.forEach(x => {
      let items = this.items.filter(i => i.currency.id === x.id)
      if(items.length > 0) {
        let currencyPaid = items.reduce(function(sum, b) {return sum + b.amount}, 0)
        let obj = new CurrencyAmount()
        obj.amount = currencyPaid
        obj.currency = x.name
        obj.symbol = x.symbol
        currencyAmountList.push(obj)
      }
    })
    this.currencyAmountList = currencyAmountList;
  }
  calculateTotalPaidByPaymentMethod() {
    const currencyAmountList = new Array<CurrencyAmount>();
    this.paymentMethods.forEach(x => {
      let items = this.items.filter(i => i.paymentType.id === x.id)
      if(items.length > 0) {
        let currencyPaid = items.reduce(function(sum, b) {return sum + b.amount}, 0)
        let obj = new CurrencyAmount()
        obj.amount = currencyPaid
        obj.name = x.name
        obj.symbol = x.currency.symbol
        obj.currency = x.currency.name
        currencyAmountList.push(obj)
      }
    })
    this.paymentMethodsList = currencyAmountList;
  }
  fetchPaymentMethods() {
    this.service.getAll('/payment-method/get-all').pipe(takeUntil(this.destroy$)).subscribe(
      data => {
        this.paymentMethods = data.filter(x => !x.isCredit)
      },
      error => console.log(error)
    )
  }

  fetchCurrencies() {
    this.service.getAll('/currency/get-all').pipe(takeUntil(this.destroy$)).subscribe(
      data => {
        this.currencies = data
      },
      error => console.log(error)
    )
  }
}
